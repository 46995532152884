import mediaQueries, {
  mediaQueriesDown,
} from '@typeform/ginger/dist/util/media-queries'
import styled, { css } from 'styled-components'

export const LogoTypeWrapper = styled.span`
  margin-left: 10.21px;
  overflow: hidden;
  position: relative;
  min-width: 150px;

  ${mediaQueries.md`
    min-width: 185px;
  `}
`
type TLogoTypeSvgProps = {
  hide: boolean
}
export const LogoTypeSvg = styled.svg<TLogoTypeSvgProps>`
  transition: transform 300ms, visibility 300ms;

  ${mediaQueriesDown.md`
    ${({ hide }: TLogoTypeSvgProps) =>
      hide &&
      css`
        visibility: hidden;
        transform: translateY(-100%);
      `}
  `}
`
type TNowYouKnowTextProps = {
  show: boolean
}
export const NowYouKnowText = styled.span<TNowYouKnowTextProps>`
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: transform 300ms, visibility 300ms;
  transform: translateY(100%);

  ${mediaQueries.md`
    font-size: 22px;
  `}

  ${mediaQueriesDown.md`
    ${({ show }: TNowYouKnowTextProps) =>
      show &&
      css`
        transform: translateY(0);
      `}
  `}
`

export const StyledLink = styled.a`
  line-height: 0;
  text-decoration: none;

  ${mediaQueries.md`
    &:hover,
    &:focus {
      ${LogoTypeSvg} {
        transform: translateY(-100%);
      }
      ${NowYouKnowText} {
        transform: translateY(0);
      }
    }
  `}
`

export const LogoWrapper = styled.div`
  display: flex;
`
